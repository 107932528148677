import React, {Fragment, useState, useEffect, useRef} from 'react'
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import {ThemeProvider} from 'styled-components'
import {lightTheme, darkTheme} from 'shared/theme'
import * as routes from 'shared/constants/routes'
import {Header} from 'components/Header'
import {Footer} from 'components/Footer'
import {ICode} from 'pages/ICode'
import {IDesign} from 'pages/IDesign'
import {IMusic} from 'pages/IMusic'
import {Opening} from 'pages/Opening'
import './App.scss'
import {AnimatePresence} from 'framer-motion'
import {Cursor} from 'components/Cursor'
import style from 'styled-components'
import {device} from 'shared/theme'
import { useLocation } from 'react-router-dom';

function ScrollToTop() {
  const {pathname} = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

const App = () => {
  const [isDark, setIsDark] = useState(true)

  // useEffect(() => {
  // const handleMouseMove = e => {
  //   const cursor = document.querySelector('#cursor');
  //   cursor.style.left = `${e.pageX}px`
  //   cursor.style.top = `${e.pageY}px`
  // }

  // const handleMouseUp = e => {
  //   const cursor = document.querySelector('#cursor');
  //   cursor.style.width = `2rem`
  //   cursor.style.height = `2rem`
  // }

  // const handleMouseDown = e => {
  //   const cursor = document.querySelector('#cursor');
  //   cursor.style.width = `3rem`
  //   cursor.style.height = `3rem`
  // }
  // window.addEventListener("mousemove", handleMouseMove)
  // window.addEventListener("mousedown", handleMouseUp)
  // window.addEventListener("mousedown", handleMouseDown)

  // return () => {
  // window.removeEventListener("mousemove", handleMouseMove);
  // window.removeEventListener("mousedown", handleMouseUp);
  // window.removeEventListener("mousedown", handleMouseDown);
  //   }
  // })

  return (
    <ThemeProvider theme={isDark ? darkTheme : lightTheme}>
      <Router>
        <AnimatePresence mode='wait'>
          <Body id='body'>
            {/* <Cursor /> */}
            <ScrollToTop />
            <Header idDark={isDark} onChangeTheme={() => setIsDark(!isDark)} />
            <RoutesContainer>
              <Routes>
                <Route path={routes.ROOT} element={<Opening />} />
                <Route path={routes.CODE} element={<ICode />} />
                <Route path={routes.DESIGN} element={<IDesign />} />
                <Route path={routes.MUSIC} element={<IMusic />} />
                {/* <Route path="*" element={<NotFoundComponent />} /> */}
              </Routes>
            </RoutesContainer>
          </Body>
        </AnimatePresence>
      </Router>
    </ThemeProvider>
  )
}

const Body = style.div`
 background-color: ${(props) => props.theme.backgroundPrimary};
 height:100%;
 max-width: 100%;
 overflow-x: hidden;
`

const RoutesContainer = style.div`
  width: calc(100vw - 100px);
  overscroll-behavior-y: none;
  transform: translateX(100px);
  @media ${device.mobile}, @media ${device.mobileS} {
    width: 100vw;
    transform: translateX(0px);
  }
`
export default App
