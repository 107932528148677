import * as React from 'react'
import {motion} from 'framer-motion'
import {Link, useSearchParams} from 'react-router-dom'
import styled from 'styled-components'

const variants = {
  open: {
    y: 0,
    opacity: 1,
    pointerEvents: 'auto', // <-- Add this
    transition: {
      y: {stiffness: 1000, velocity: -100},
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    pointerEvents: 'none', // <-- Add this
    transition: {
      y: {stiffness: 1000},
    },
  },
}

const NavItem = ({name, link}) => {
  const [searchParams, set] = useSearchParams()
  const isOpen = searchParams.get('isNavOpen') === 'true'

  return (
    <motion.li
      animate={isOpen ? 'open' : 'closed'}
      initial='closed'
      variants={variants}
      whileHover={{scale: 1.02}}
      whileTap={{scale: 0.95}}
      style={{listStyle: 'none', pointerEvents: 'auto'}} // <-- Add pointerEvents here as default
    >
      <NavLink to={link}>{name}</NavLink>
    </motion.li>
  )
}

const NavLink = styled(Link)`
  text-decoration: none;
  font-size: 9vw;
  font-weight: 1000;
  width: 100%;
  color: ${(props) => props.theme.textPrimary};
  margin-top: 2rem;
  &.hover {
    text-shadow:
      -8px -8px 15px #333333,
      8px 8px 15px #0d0d0d;
  }
`
export default NavItem
