import React from 'react'
import styled from 'styled-components'
import {DescriptionBlock} from 'components/DescriptionBlock'
import {motion} from 'framer-motion'
import {TextBlock} from 'components/TextBlock'
import {Slides} from 'components/Slides'
import {device} from 'shared/theme'
// const imageSet = [
//   {src: party1, des: 'des'},
//   {src: party2, des: 'des'},
//   {src: party3, des: 'des'},
// ]

const CanvaEmbed = () => {
  return (
    <div style={{gridColumnStart: 2}}>
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: 0,
          paddingTop: '56.2500%',
          paddingBottom: 0,
          boxShadow: '0 2px 8px 0 rgba(63,69,81,0.16)',
          marginTop: '1.6em',
          marginBottom: '0.9em',
          overflow: 'hidden',
          borderRadius: '8px',
          willChange: 'transform',
        }}>
        <iframe
          loading='lazy'
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: 0,
            left: 0,
            border: 'none',
            padding: 0,
            margin: 0,
          }}
          src='https://www.canva.com/design/DAFuCVEbDg4/view?embed'
          allowFullScreen
          allow='fullscreen'></iframe>
      </div>
      <a
        href='https://www.canva.com/design/DAFuCVEbDg4/view?utm_content=DAFuCVEbDg4&amp;utm_campaign=designshare&amp;utm_medium=embeds&amp;utm_source=link'
        target='_blank'
        rel='noopener noreferrer'>
        d
      </a>{' '}
      by shuai wang
    </div>
  )
}

const descDetail = (
  <p>
    In the bustling realm of event planning and entertainment, a significant gap exists: talented
    artists, especially those less extroverted, often remain undiscovered, while event organizers
    grapple with fragmented communication and coordination processes. Enter Syecho, a groundbreaking
    platform designed to bridge this divide.
    <br />
    <span> Key Features:</span>
    <ul>
      <li>
        Unified Communication: Streamlined communication tools ensure artists and event hosts
        connect efficiently, eliminating the hesitations and delays that plague traditional outreach
        methods.
      </li>
      <li>
        Dynamic Portfolios: Artists can craft customizable portfolios, showcasing their unique
        skills and styles beyond the constraints of generic social media landing pages.
      </li>
      <li>
        Collaborative Event Boards: Centralized boards foster seamless coordination, ensuring
        clarity in roles and responsibilities, and fostering pre-event familiarity among artists.
      </li>
      <li>
        Feedback Loop: Post-event feedback mechanisms nurture growth, understanding, and the
        establishment of long-term relationships in the entertainment community.
      </li>

      <h2>Objective:</h2>
      <p>
        Syecho's mission is to amplify the voices of hidden talents, ensuring they're seen and
        heard. By providing a cohesive platform for discovery, communication, and collaboration,
        Syecho aims to revolutionize the event planning landscape, ensuring every event is graced
        with the best talent and every artist gets their moment in the spotlight.
      </p>
    </ul>
    <br />
  </p>
)

const EventsManager = () => {
  return (
    <SyechoContainer
      initial='exit'
      animate='enter'
      exit='exit'
      variants={{exit: {transition: {staggerChildren: 0.1}}}}>
      <DescriptionBlock
        title='Syecho'
        brief='In the bustling realm of event planning and entertainment, a significant gap exists: talented artists, especially those less extroverted, often remain undiscovered, while event organizers grapple with fragmented communication and coordination processes.'
        stack=' Remix | Prisma | Cypress.js | Tailwind | TypeScript '
        platform=' Web/Mobile'
        category='FullStack/Design'
        creator='Shuai Wang'
        plink='https://syecho.fly.dev'
        glink='https://github.com/Aidanwang0309/Syecho'
      />

      <TextBlock title='Description and Technology' description={descDetail} />
      <Container>
        <CanvaEmbed />
      </Container>
    </SyechoContainer>
  )
}

const SyechoContainer = styled.div`
  background-image: repeating-linear-gradient(#ccc 0 0px, transparent 0px 100%),
    repeating-linear-gradient(90deg, #cccccc1f 0 1px, transparent 1px 100%);
  background-size: 16.7% 100px;
  margin-left: 0px;
  padding-bottom: 10vw;
  
  @media ${device.mobile} {
    ${
      '' /* margin-left:0px;
    background-image: repeating-linear-gradient(#ccc 0 0px, transparent 0px 100%),
    repeating-linear-gradient(90deg,#cccccc1f 0 1px, transparent 1px 100%);
    background-size: 20% 100px; */
    }
  }
  @media ${device.tablet} {
    margin-left: 100px;
    background-image: repeating-linear-gradient(#ccc 0 0px, transparent 0px 100%),
      repeating-linear-gradient(90deg, #cccccc1f 0 1px, transparent 1px 100%);
    background-size: 25% 100px;
  }

  @media ${device.laptop} {
    background-image: repeating-linear-gradient(#ccc 0 0px, transparent 0px 100%),
      repeating-linear-gradient(90deg, #cccccc1f 0 1px, transparent 1px 100%);
    background-size: 16.7% 100px;
  }
`

const Container = styled.div`
  position: relative;
  width: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;

  @media ${device.mobile} {
  }
  @media ${device.tablet} {
  }
  @media ${device.laptop} {
    grid-template-columns: 1fr 4fr 1fr;
  }
`

export default EventsManager
