import React from 'react'
import styled from 'styled-components'
import {Block} from 'components/Block'
import {CreativeBlock} from 'components/CreativeBlock'
import {motion} from 'framer-motion'
import passenger from 'assets/passenger.png'
import spotifycover from 'assets/spotifycover.png'
import UI4 from 'assets/RandomUI.png'
import lawcover from 'assets/lawcover.png'
import {device} from 'shared/theme'
import {Law} from 'projects/Law'
import {Spotify} from 'projects/Spotify'
import {RandomUI} from 'projects/RandomUI'
import {Passenger} from 'projects/Passenger'
import {Route, Routes, useMatch} from 'react-router-dom'

const IDesignBlockData = [
  {
    id: 1,
    Component: Passenger,
    title: '01.Passenger',
    categories: ['UI', 'UX'],
    image: passenger,
    link: 'passenger',
    size: 1,
    grid: 'g1',
  },
  {
    id: 2,
    Component: Spotify,
    title: `02.Spotify Lib Redesign`,
    categories: ['UI', 'UX'],
    image: spotifycover,
    link: 'spotify',
    size: 1,
    grid: 'g2',
  },
  {
    id: 3,
    Component: RandomUI,
    title: '03.Random UI',
    categories: ['UI', 'UX'],
    image: UI4,
    link: 'random',
    size: 1,
    grid: 'g3',
  },
  {
    id: 4,
    Component: Law,
    title: '04.Law Manager',
    categories: ['UI', 'UX'],
    image: lawcover,
    link: 'law-manager',
    size: 1,
    grid: 'g4',
  },
]

const IDesign = () => {
  return (
    <ProjectContainer
      initial='initial'
      animate='enter'
      exit='exit'
      variants={{exit: {transition: {staggerChildren: 0.01}}}}>
      <CreativeBlock word='Design' width={2} />
      {IDesignBlockData.map(({id, title, image, link, size, position, grid, categories}) => (
        <Block
          key={id}
          id={id}
          title={title}
          img={image}
          link={link}
          grid={grid}
          categories={categories}
        />
      ))}
    </ProjectContainer>
  )
}

const IDesignRouter = () => {
  return (
    <Routes>
      {IDesignBlockData.map(({Component, link}, key) => {
        return <Route path={`${link}`} key={key} element={<Component />} />
      })}
      <Route path='/' element={<IDesign />} />
    </Routes>
  )
}

const ProjectContainer = styled(motion.div)`
  z-index: 999 !important;
  position: relative;
  height: auto;
  display: grid;
  padding-bottom: 10vw;
  padding-top: 20vw;


  @media ${device.mobile} {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: repeat(12, calc(35vw - 0.1rem));
    margin-left: 0px;
    grid-template-areas:
      '. g1 g1 g1 .'
      '. g2 g2 g2 .'
      '. g3 g3 g3 .'
      '. g4 g4 g4 .';
    background-image: repeating-linear-gradient(#ccc 0 0px, transparent 0px 100%),
      repeating-linear-gradient(90deg, #cccccc1f 0 1px, transparent 1px 100%);
    background-size: 20% 100px;
  }

  @media ${device.tablet} {
    top: 0;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: repeat(4, calc(20vw - 2.4rem));
    grid-template-areas:
      '. g1 g1 g1'
      '. g2 g2 g2'
      '. g3 g3 g3'
      '. g4 g4 g4';
    background-image: repeating-linear-gradient(#ccc 0 0px, transparent 0px 100%),
      repeating-linear-gradient(90deg, #cccccc1f 0 1px, transparent 1px 100%);
    background-size: 25% 100px;
  }

  @media ${device.laptop} {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: repeat(4, calc(20vw - 2.4rem));
    grid-template-areas:
      '. g1 g1 g1 g1 .'
      '. g2 g2 g2 g2 .'
      '. g3 g3 g3 g3 .'
      '. g4 g4 g4 g4 .';
    background-image: repeating-linear-gradient(#ccc 0 0px, transparent 0px 100%),
      repeating-linear-gradient(90deg, #cccccc1f 0 1px, transparent 1px 100%);
    background-size: 16.7% 100px;
  }
`

// calc(33vw - 2.4rem)
export default IDesignRouter
