import React, {Suspense, useEffect, useRef, useState} from 'react'
import styled from 'styled-components'
import {Link} from 'react-router-dom'
import {motion} from 'framer-motion'
import {device} from 'shared/theme'
import avatar from 'assets/avatar.png'

const navItemContainerVariants = {
  initial: {
    height: 0,
  },
  enter: {
    height: '100%',
    transition: {
      duration: 0.5,
    },
  },
  exit: {
    opacity: 0,
    transition: {duration: 3},
  },
}

const navItemVariants = {
  initial: {
    y: '0%',
  },
  enter: {
    y: '-50%',
    // opacity: 1,
    transition: {
      duration: 30,
      yoyo: Infinity,
      // delay: 1,
    },
  },
  exit: {
    opacity: 0,
    transition: {duration: 3},
  },
}

const navItemVariants2 = {
  initial: {
    y: '-50%',
  },
  enter: {
    y: '0%',
    // opacity: 1,
    transition: {
      duration: 30,
      yoyo: Infinity,
      // delay: 1,
    },
  },
  exit: {
    opacity: 0,
    transition: {duration: 3},
  },
}

const ProfileVariants = {
  initial: {},
  enter: {
    transition: {
      duration: 1,
      delay: 1.5,
      staggerChildren: 0.5,
    },
  },
  exit: {},
}

const ProfileItemVariants = {
  initial: {
    y: -100,
    opacity: 0,
  },
  enter: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 2,
    },
  },
  exit: {},
}

const navContainerVariants = {
  initial: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
    transition: {duration: 1, staggerChildren: 0.5},
  },
  exit: {
    opacity: 0,
    transition: {duration: 1, staggerChildren: 0.5},
  },
}

const LinkMap = [
  {link: '/icode', text: 'CODER CODER CODER'},
  {link: '/imusic', text: 'MUSIC PRODUCER '},
  {link: '/icode', text: 'INTERACTIVE ARTIST'},
  {link: '/idesign', text: 'UI & UX DESIGNER'},
  {link: '/', text: 'EXPLORER EXPLORER'},
  {link: '/', text: 'PHOTOGRAPHER PHOTOGRAPHER'},
]

const Opening = () => {
  return (
    <OpenContainer initial='initial' animate='enter' exit='exit' variants={navContainerVariants}>
      <Profile variants={ProfileVariants}>
        <motion.span variants={ProfileItemVariants}>
          FULL-STACK <span style={{color: '#4ebaba'}}>DEVELOPER</span> <br />
          UI/UX <span style={{color: '#EF6262'}}> DESIGNER </span> <br />
          MUSIC <span style={{color: '#F3AA60'}}> PRODUCER </span>
        </motion.span>
        <br />
        <br />
        <motion.span variants={ProfileItemVariants}>
          CRAFTING MULTIMEDIA EXPERIENCES TO
          <span style={{color: '#F3AA60'}}> CONNECT</span> <br />
          <span style={{color: '#EF6262'}}> INSPIRE</span> <br /> &{' '}
          <span style={{color: '#4ebaba'}}>ENGAGE</span>
        </motion.span>
      </Profile>

      {LinkMap.map((link, index) => (
        <IntroContainer key={index} index={index} variants={navItemContainerVariants}>
          <NavItemOverlay index={index} img={avatar} className={`nav-item-${index}`}>
            <NavItem
              variants={[0, 2, 4].includes(index) ? navItemVariants : navItemVariants2}
              index={index}>
              <NavLink index={index} to={link.link}>
                {link.text}
              </NavLink>
            </NavItem>
          </NavItemOverlay>
        </IntroContainer>
      ))}
    </OpenContainer>
  )
}

const OpenContainer = styled(motion.div)`
  height: ${(props) => `${props.height}px`};
  overflow: hidden;
  box-sizing: border-box;
  display: grid;

  @media ${device.mobile}, @media ${device.mobileS} {
    height: calc(100vh - 10vw - 40px);
    position: relative;
    background-image: repeating-linear-gradient(#ccc 0 0px, transparent 0px 100%),
      repeating-linear-gradient(90deg, #cccccc1f 0 1px, transparent 1px 100%);
    background-size: 33.3% 100px;
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${device.tablet} {
    ${'' /* top: 0; */}
    background-image: repeating-linear-gradient(#ccc 0 0px, transparent 0px 100%),
      repeating-linear-gradient(90deg, #cccccc1f 0 1px, transparent 1px 100%);
    background-size: 25% 100px;
    grid-template-columns: repeat(4, 1fr);
  }

  @media ${device.laptop} {
    background-image: repeating-linear-gradient(#ccc 0 0px, transparent 0px 100%),
      repeating-linear-gradient(90deg, #cccccc1f 0 1px, transparent 1px 100%);
    background-size: 20% 100px;
    grid-template-columns: repeat(5, 1fr);
  }

`

const NavItemOverlay = styled(motion.div)`
  height: 100vh;
  background-size: cover;
  background-repeat: no-repeat;

  &[index='0'] {
    @media ${device.mobile}, @media ${device.mobileS} {
      height: 100vw;
      transform: translate(-5vw, 90vw);
      background-position-x: 15vw;
    }
  }

  &[index='1'] {
    @media ${device.mobile}, @media ${device.mobileS} {
      height: 100vw;
      transform: translate(0, 90vw);
      background-position-x: -5vw;
    }
  }

  &[index='2'] {
    @media ${device.mobile}, @media ${device.mobileS} {
      height: 145vw;
      transform: translate(5vw, 45vw);
      background-position-x: -10vw;
    }
  }

  @media ${device.mobile}, @media ${device.mobileS} {
    height: 45vh;
    transform: ${(props) => `translateY(${50}vh)`};
    background-image: ${(props) => `url(${[0, 1, 2].includes(props.index) ? props.img : null})`};
    background-position-x: ${(props) => {
      return `calc(${(props.index - 1) * -1} * ((100vw) / 3))`
    }};
  }

  @media ${device.tablet} {
    height: 100vh;
    transform: none;
    background-image: ${(props) =>
      `url(${props.index >= 2 && props.index < 4 ? props.img : null})`};
    background-position-x: ${(props) => {
      return `calc(${(props.index - 2) * -1} * ((100vw - 100px) / 4))`
    }};
  }

  @media ${device.laptop} {
    background-image: ${(props) =>
      `url(${props.index >= 2 && props.index < 5 ? props.img : null})`};
    background-position-x: ${(props) => {
      return `calc(${(props.index - 2) * -1} * ((100vw - 100px) / 5))`
    }};
  }
`

const NavItem = styled(motion.div)`
  @media ${device.mobile} {
    opacity: 0.1;
  }
  @media ${device.tablet} {
    opacity: 1;
  }
`

const NavLink = styled(Link)`
  text-decoration: none;
  font-size: 14vw;
  font-weight: 1000;
  width: 100%;
  white-space: nowrap;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  color: ${(props) => props.theme.textPrimary};
  opacity: ${(props) => (props.index < 3 ? 0.05 : 0.5)};
`

const Profile = styled(motion.div)`
  position: absolute;
  font-size: 3.5vw;
  color: ${(props) => props.theme.textPrimary};
  z-index: 999;
  font-weight: 400;
  width: 40vw;
  margin-top: 10rem;
  padding-left: 6vw;
  text-align: right;
  span {
    font-weight: 1000;
  }

  @media ${device.mobile}, @media ${device.mobileS} {
    padding-left: 10vw;
    font-weight: 1000;
    font-size: 5.5vw;
    width: 75vw;
    text-align: left;
    margin-top: 3rem;
  }
  @media ${device.tablet} {
    margin-top: 5rem;
    padding-left: 6vw;
    font-size: 4.5vw;
    width: 40vw;
    text-align: right;

    /* margin-top: 10rem;
    padding-left: 6vw;
    font-size: 4.5vw;
    width: 40vw;
    text-align: right;
    span {
      font-weight: 1000;
    } */
  }
  @media ${device.laptop} {
    margin-top: 5rem;
    padding-left: 4.5vw;
    font-size: 3.5vw;
    width: 35vw;
    text-align: right;
    span {
      font-weight: 1000;
    }
  }
  @media ${device.desktop} {
    margin-top: 5rem;
  }
`

const IntroContainer = styled(motion.div)`
  background: ${(props) => props.theme.backgroundPrimary};
  z-index: 99;
  overflow: hidden;
  box-sizing: border-box;

  p {
    text-decoration: none;
    font-size: 7vw;
    font-weight: 1000;
    color: ${(props) => props.theme.textPrimary};
    margin: 0;
    margin-top: 5rem;
  }

  @media ${device.mobile}, @media ${device.mobileS} {
    display: ${(props) => (props.index > 2 && props.index < 6 ? 'none !important' : 'block')};
  }

  @media ${device.tablet} {
    display: ${(props) => (props.index > 3 && props.index < 6 ? 'none !important' : 'block')};
  }

  @media ${device.laptop} {
    display: ${(props) => (props.index > 4 && props.index < 6 ? 'none !important' : 'block')};
  }

`
export default Opening
