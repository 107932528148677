export const lightTheme = {
  backgroundPrimary: '#fbf9f5',
  textPrimary: '#000000',
  textSecondary: '#474747'
};

export const darkTheme = {
  backgroundPrimary: '#202020',
  textPrimary: '#c0c0c0',
  textSecondary: '#474747',
  textLink:'#21efe5',
};

const size = {
  mobile: '320px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px'
};

export const device = {
  mobileS: `(max-width: ${size.mobile})`,
  mobile: `(min-width: ${size.mobile}) and (max-width: ${parseInt(size.tablet) - 1}px)`,
  tablet: `(min-width: ${size.tablet}) and (max-width: ${parseInt(size.laptop) - 1}px)`,
  laptop: `(min-width: ${size.laptop})`,
};


// @media ${device.mobile} {}

// @media ${device.tablet} {}

// @media ${device.laptop} {}
