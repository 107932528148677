import React, {useState, useRef} from 'react'
import styled from 'styled-components'
import {CreativeBlock} from 'components/CreativeBlock'
import {motion} from 'framer-motion'
import {device} from 'shared/theme'
import ReactPlayer from 'react-player'

const IMusic = ({match}) => {
  const [playing, setPlaying] = useState(false)
  const [volume, setVolume] = useState(0.8)
  const [played, setPlayed] = useState(0)
  const playerRef = useRef(null)

  const handleProgress = (state) => {
    setPlayed(state.played)
  }

  return (
    <ProjectContainer
      initial='initial'
      animate='enter'
      exit='exit'
      variants={{exit: {transition: {staggerChildren: 0.01}}}}>
      <Container>
        <div>
          <ReactPlayer
            ref={playerRef}
            url={'https://soundcloud.com/aspirin-wang/sets/house-releases'}
            playing={playing}
            volume={volume}
            onProgress={handleProgress}
            width='100%'
            height='auto' // reduced height
            className=''
            config={{
              soundcloud: {
                options: {
                  auto_play: false,
                  // color: '#7338f8',
                  // show_artwork: true,
                  // show_comments: true,
                  // show_playcount: true,
                  // show_user: true,
                  // hide_related: true,
                  // visual: true,
                  // show_reposts: false,
                  // show_teaser: false,
                  // single_active: false,
                  // buying: false,
                  // sharing: false,
                  // download: true,
                  // liking: false,
                },
              },
            }}
          />
        </div>
        <div>
          <ReactPlayer
            ref={playerRef}
            url={'https://soundcloud.com/aspirin-wang/sets/remix-songs-that-i-reworked'}
            playing={playing}
            volume={volume}
            onProgress={handleProgress}
            width='100%'
            height='auto' // reduced height
            className=''
            config={{
              soundcloud: {
                options: {
                  auto_play: false,
                  // color: '#7338f8',
                  // show_artwork: true,
                  // show_comments: true,
                  // show_playcount: true,
                  // show_user: true,
                  // hide_related: true,
                  // visual: true,
                  // show_reposts: false,
                  // show_teaser: false,
                  // single_active: false,
                  // buying: false,
                  // sharing: false,
                  // download: true,
                  // liking: false,
                },
              },
            }}
          />
        </div>
        <div>
          <ReactPlayer
            ref={playerRef}
            url={'https://soundcloud.com/aspirin-wang/sets/original-release-sonic'}
            playing={playing}
            volume={volume}
            onProgress={handleProgress}
            width='100%'
            height='auto' // reduced height
            className=''
            config={{
              soundcloud: {
                options: {
                  auto_play: false,
                  // color: '#7338f8',
                  // show_artwork: true,
                  // show_comments: true,
                  // show_playcount: true,
                  // show_user: true,
                  // hide_related: true,
                  // visual: true,
                  // show_reposts: false,
                  // show_teaser: false,
                  // single_active: false,
                  // buying: false,
                  // sharing: false,
                  // download: true,
                  // liking: false,
                },
              },
            }}
          />
        </div>
      </Container>
      {/* <CreativeBlock word="Music" width={2} /> */}
    </ProjectContainer>
  )
}

const ProjectContainer = styled(motion.div)`
  position: relative;
  height: auto;
  height: 100vh;
  z-index: 99;
  margin-left: 0;

  @media ${device.mobile} {
    top: 20vw;
  }

  @media ${device.tablet} {
    top: 0;
    margin-left: 100px;
    background-image: repeating-linear-gradient(#ccc 0 0px, transparent 0px 100%),
      repeating-linear-gradient(90deg, #cccccc1f 0 1px, transparent 1px 100%);
    background-size: 25% 100px;
  }

  @media ${device.laptop} {
    background-image: repeating-linear-gradient(#ccc 0 0px, transparent 0px 100%),
      repeating-linear-gradient(90deg, #cccccc1f 0 1px, transparent 1px 100%);
    background-size: 16.7% 100px;
  }
`

const Container = styled.div`
  @media ${device.mobile} {
    height: 50%;
    width: 90vw;
    padding: 5vh 0;
    margin: 0 auto;
  }

  @media ${device.laptop} {
    height: 70%;
    width: 60vw;
    padding: 5vh 0;
    margin: 0 auto;
  }

  div{
    margin-bottom: 2rem;
  }
`

// calc(33vw - 2.4rem)
export default IMusic
