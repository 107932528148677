import {Suspense, useState, useRef, useEffect} from 'react'
import {Link, useSearchParams, useLocation} from 'react-router-dom'
import styled from 'styled-components'
import debounce from 'lodash/debounce'
import {ReactComponent as TextLogo} from 'assets/LOGO_TEXT.svg'
import {motion} from 'framer-motion'
import {useDimensions} from 'shared/hooks'
import {NavButton} from 'components/NavButton'
import {NavList} from 'components/NavList'
import {ReactComponent as AspirinLogo} from 'assets/aspirin_logo.svg'
import {device} from 'shared/theme'
import INSTA from 'assets/instagram.svg'
import SOUNDCLOUD from 'assets/soundcloud.svg'
import LINKEDIN from 'assets/linkedin.svg'
import Pills from 'components/Pills/Pills'

const navList = [
  {name: 'code', link: '/icode'},
  {name: 'music', link: '/imusic'},
  {name: 'design', link: '/idesign'},
]

const icons = [
  {link: 'https://www.instagram.com/aspirincity/', icon: INSTA},
  {link: 'https://soundcloud.com/aspirin-wang', icon: SOUNDCLOUD},
  {link: 'https://www.linkedin.com/in/swang1993/', icon: LINKEDIN},
]

const iconTextVariants = {
  initial: {
    pathLength: 0,
    transition: {
      duration: 4,
    },
  },
  enter: {
    pathLength: 1,
    transition: {
      duration: 2,
    },
  },
}
const iconVariants = {
  initial: {
    pathLength: 0,
    transition: {
      duration: 1,
    },
  },
  enter: {
    pathLength: 0.5,
    transition: {
      duration: 1,
    },
  },
}
const navContainerVariants = {
  enter: {
    opacity: 1,
    transition: {
      delayChildren: 2,
      staggerChildren: 0.3,
    },
    width: '100%',
  },

  exit: {
    opacity: 0,
    width: 0,
    transition: {
      duration: 2,
      staggerChildren: 0.3,
    },
  },

  // initial: {
  //   opacity: 0,
  //   width: 0,
  // },
}
const logoVariants = {
  enter: {
    opacity: 1,
    transition: {
      duration: 1,
    },
    y: -5,
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 3,
      staggerChildren: 0.3,
    },
  },
  initial: {
    opacity: 0,
    y: -30,
  },
}

// const GlowingLogo = () => {
//   return (
//     <motion.svg width='200' height='200' xmlns='http://www.w3.org/2000/svg'>
//       {/* Definitions: Gradient and Filter */}

//       <AspirinLogo stroke='url(#glowingGradient)' />

//     </motion.svg>
//   )
// }

const SVGComponent = ({xmlns, viewBox, children}) => (
  <motion.svg xmlns={xmlns} xmlnsXlink='http://www.w3.org/1999/xlink' viewBox={viewBox}>
    {children}
  </motion.svg>
)

const Header = ({isDark, onChangeTheme}) => {
  const [isOpen, setIsOpen] = useState(false)
  const location = useLocation()

  useEffect(() => {
    // Check the search parameters and update isOpen state accordingly
    const params = new URLSearchParams(location.search)
    if (params.has('isNavOpen')) {
      // Check if a specific key exists in the search parameters
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [location.search])

  const [searchParams, setSearchParams] = useSearchParams()

  const containerRef = useRef(null)
  const {height} = useDimensions(containerRef)

  // const [checked, setChecked] = useState(false)
  // const handleChange = () => {
  //   setChecked((x) => !x)
  //   onChangeTheme()
  // }

  const handleToggle = () => {
    setIsOpen(!isOpen)
    isOpen ? setSearchParams({}) : setSearchParams({isNavOpen: !isOpen})
  }

  const sidebar = {
    closed: {
      clipPath: 'circle(0px at 40px 40px)',
      transition: {
        damping: 10, // Adjusted value
        stiffness: 100, // Increased stiffness for faster motion
        type: 'spring',
      },
    },
    open: (height = 1000) => ({
      clipPath: `circle(${height * 2 + 800}px at 40px 40px)`,
      transition: {
        damping: 20, // Adjusted value
        stiffness: 60, // Increased stiffness for faster motion
        type: 'spring',
        restDelta: 2,
      },
    }),
  }

  return (
    <NavContainer initial='initial' animate='enter' exit='exit' variants={navContainerVariants}>
      <Nav initial='close' exit='exit' animate={isOpen ? 'open' : 'closed'} ref={containerRef}>
        <NavList navList={navList} icons={icons} isOpen={isOpen} />
        {/* <motion.div>
          {icons.map(icon => <a href={icon.link} style={{ backgroundImage: `url(${icon.icon})` }}>  </a>)}
        </motion.div> */}
        <NavBg
          className='background'
          initial='closed'
          style={{
            height: `${isOpen ? '100%' : '0%'}`,
          }}
          variants={sidebar}
          animate={isOpen ? 'open' : 'closed'}>
          {/* <Particles></Particles> */}
          <Suspense fallback={null}>
            <Pills speed={1} />
          </Suspense>
        </NavBg>
        <NavButton toggle={() => handleToggle()} onClick={handleToggle} />
      </Nav>
      {/*  */}
      <LogoText>
        <motion.svg
          width='170'
          height='52'
          viewBox='0 0 170 52'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'>
          <motion.path
            initial='initial'
            animate='enter'
            variants={iconTextVariants}
            d='M86.0147 0.662109H25.4914C11.4128 0.662109 0 12.0749 0 26.1535C0 40.2322 11.4128 51.645 25.4914 51.645H86.0147L79.6195 26.4306L86.0147 0.662109ZM79.3348 46.171H26.1055C15.0522 46.171 6.09575 37.2143 6.09575 26.161C6.09575 15.1077 15.0522 6.1513 26.1055 6.1513H79.3348L74.3103 26.3782L79.3348 46.171Z'
            // fill='#FF5C00'
            fill='none'
            stroke='#FF5C00'
            strokeWidth='2'
          />
          <motion.path
            initial='initial'
            animate='enter'
            variants={iconTextVariants}
            d='M144.509 0.662109H91.8934L90.5833 6.1513H143.895C154.948 6.1513 163.905 15.1078 163.905 26.161C163.905 37.2143 154.94 46.171 143.895 46.171H90.8822L92.2452 51.66H144.509C158.588 51.66 170 40.2472 170 26.1685C170 12.0898 158.588 0.662109 144.509 0.662109Z'
            // fill='white'
            fill='none'
            stroke='#FF5C00'
            strokeWidth='2'
          />
          <motion.path
            initial='initial'
            animate='enter'
            variants={iconTextVariants}
            d='M28.9138 16.1187L31.5123 35.4845H25.6262L24.9448 30.22H16.7298L14.4382 35.4845L8.72437 35.3123L16.4302 17.0773L15.4342 16.1187H28.9138ZM21.3427 19.4063L18.0552 26.9324H24.6303L23.6343 19.4063H21.3427ZM48.0101 31.5454L48.6616 27.6139H35.5188L37.1588 16.1187H56.5319L55.8804 21.6979H49.3053L49.65 19.4063H43.3894L42.3934 24.3339H55.5357L54.2327 33.193C54.2327 33.5149 54.1506 33.822 53.9936 34.1141C53.8587 34.3912 53.6639 34.6308 53.4092 34.8329C53.1623 35.0352 52.8704 35.1999 52.5557 35.3123C52.256 35.4246 51.9413 35.4845 51.5966 35.4845H34.5228L35.1743 30.22L36.1703 31.5529H48.0101V31.5454ZM82.2856 16.1187L79.6196 26.4382L80.0092 27.9809H66.4994L65.6081 35.477H59.3855L61.9167 17.0698L59.6246 16.1113H82.2856V16.1187ZM67.8324 19.3239L66.9411 24.8805H74.6843L76.0623 19.3239H67.8324ZM120.29 16.1187L119.197 25.9739C119.197 26.2959 119.115 26.6029 118.957 26.895C118.823 27.1721 118.628 27.4117 118.374 27.6139C118.119 27.8161 117.834 27.9809 117.52 28.0932C117.22 28.2055 116.906 28.2654 116.561 28.2654H112.285L117.52 33.193L120.29 35.4845H111.97L105.395 28.2654L104.4 35.4845H97.8248L98.4984 33.193L100.116 17.4218V16.1187H120.29ZM106.392 19.4063L105.71 24.9854H112.967L113.618 19.4063H106.392ZM132.737 16.1187L130.737 35.4845H125.405L127.405 16.1187H132.737ZM145.415 16.1187L152.297 28.2654L153.937 16.1187H161.41L159.411 17.317L156.918 35.477H150.343L143.768 23.6374L142.128 35.477H135.178L136.511 33.5299L138.84 16.1113H145.415V16.1187Z'
            fill='none'
            // fill='white'
            stroke='white'
            strokeWidth='2'
          />
          <motion.path
            initial='initial'
            animate='enter'
            variants={iconTextVariants}
            d='M94.2526 24.139L90.0966 23.847L90.6803 15.4597L83.0798 27.464L87.2358 27.7561L86.6514 36.1434L94.2526 24.139Z'
            // fill='white'
            // fill='none'
            fill='#FF5C00'
            stroke='white'
            strokeWidth='2'
          />
        </motion.svg>
      </LogoText>
    </NavContainer>
  )
}

const NavBg = styled(motion.div)`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100vw;
  background: ${(props) => props.theme.backgroundPrimary};

  @media ${device.mobile}, @media ${device.mobileS} {
    top: 5rem;
    left: 0px;
    width: 100vw;
  }

  @media ${device.tablet} {
    top: 0px;
    left: 101px;
    width: 100vw;
  }
`

const NavContainer = styled.section`
  z-index: 9999;
  ${'' /* position: relative; */}
  position: fixed;
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => props.theme.backgroundPrimary};
  align-items: center;
  align-content: center;
  flex-direction: column;
  width: 100px;
  height: 100%;
  border-bottom: 1px solid #ffffff40;

  @media ${device.mobile}, @media ${device.mobileS} {
    position: relative;
    height:calc(10vw + 40px);
    flex-direction: row-reverse;
    width: 100%;
    ${'' /* padding: 2rem; */}
    margin-top: 0rem;
    border-bottom: 1px solid #ffffff40;
    border-left: ${(props) => `o solid ${props.theme.textPrimary}`};
  }
`

const Nav = styled(motion.nav)`
  display: flex;
  justify-content: center;
  align-content: center;
  position: relative;
  margin-top: 5vw;
  width: 60px;
  height: 60px;

  ul {
    height: 100%;
    left: 10vw;
    top: 0;
    li {
      margin-bottom: 3vh;
      a {
        font-size: 10vw;
      }
    }
  }
  button {
    z-index: 9999;
    ${
      '' /* position: absolute;
    right: 5vw;
    top: 5vw; */
    }
  }

  @media ${device.mobile}, @media ${device.mobileS} {
    height: 100%;
    border-left: 5solid #ffffff40;
    ul {
      height: 40%;
      left: 0;
      top: 0%;
      margin-top: 5rem;
      li {
        margin-bottom: 1vh;
        border-bottom: 1px solid #ffffff40;
        line-height: 8vh;
        a {
          font-size: 10vw;
        }
      }
    }
    button {
      position: absolute;
      padding: 0;
      top: 3vw;
      width: 35px;
      height: 35px;
    }
  }

  @media ${device.tablet} {
    ul {
      left: 20%;
      top: 15%;
      li > a {
        font-size: 6rem;
      }
    }
  }
`

const LogoText = styled(motion.div)`
  height: 100%;
  width: 100%;
  position: relative;
  right: 10%;
  overflow: hidden;
  svg {
    transform-origin: 75px 40px;
    transform: rotate(-90deg);
    position: absolute;
    bottom: 10%;
    width: 135px;
    @media ${device.mobile}, @media ${device.mobileS} {
      transform: none;
      position: relative;
      width: 115px;
      margin-top: 6vw;
    }
  }

  @media ${device.mobile}, @media ${device.mobileS} {
    left: 5%;
    width: auto;
  }
`

// const Nav = styled.div`
//       position: relative;
//       overflow: hidden;
//       display: grid;
//       grid-template-columns:1fr 1fr 1fr
//       width: 100%;
//       line-height: 80px;
//       justify-content: space-around;
//       text-align: center;
//       transition: height 0.3s linear;
//     `;

// const NavLink = styled(Link)`
//       height: 100%;
//       width: 100%;
//       text-decoration: none;
//       color: black;
//   :hover {
//         transition:all 0.5s ease-in-out;
//       background-color: #8080803d;
//     }
//   `;

export default Header

// const Logo = styled(motion.div)`
//   align-self: center;
//   height: 48px;
//   width: 48px;
//   line-height: 1.5rem;
//   text-align: left;
//   border-right: 1px solid #ffffff40;
//   position: relative;
//   left: 0;
//   top: 0;
//   border-right: 0px;
//   margin: 0 auto;
//   svg {
//     margin: 0 auto;
//     width: 48px;
//     height: 48px;
//   }

//   @media ${device.mobile}, @media ${device.mobileS} {
//     ${'' /* position: absolute; */}
//     left: 0;
//     top: 0;
//     width: 12%;
//     box-sizing: border-box;
//     height: 100%;
//     border-right: 1px solid #ffffff40;
//     svg {
//       margin: 0 auto;
//       width: 35px;
//       height: 35px;
//       ${'' /* padding: 5vw; */}
//     }
//   }
// `
