import * as React from 'react'
import {useSearchParams} from 'react-router-dom'
import {motion} from 'framer-motion'
import {NavItem} from '../NavItem'
import styled from 'styled-components'
import {device} from 'shared/theme'

const variants = {
  open: {
    opacity: 1,
    height: '100%',
    transition: {staggerChildren: 0.02, delayChildren: 0.2},
  },
  closed: {
    opacity: 0,
    height: 0,
    transition: {staggerChildren: 0.02, staggerDirection: -1},
  },
}

const iconVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: {stiffness: 100, velocity: -100},
    },
  },
  closed: {
    y: 100,
    opacity: 0,
    transition: {
      y: {stiffness: 100},
    },
  },
}

const NavList = ({navList, icons, isOpen}) => {
  return (
    <List variants={variants} initial='closed' animate={isOpen ? 'open' : 'closed'}>
      {navList.map((item, index) => (
        <NavItem
          key={index}
          name={item.name}
          link={item.link}
          animate={isOpen ? 'open' : 'closed'}
        />
      ))}
      <SocialIcon variants={variants}>
        {icons.map((icon, index) => (
          <Icon
            key={index}
            variants={iconVariants}
            href={icon.link}
            style={{backgroundImage: `url(${icon.icon})`}}>
            {' '}
          </Icon>
        ))}
      </SocialIcon>
    </List>
  )
}

const List = styled(motion.ul)`
  z-index: 9999;
  position: fixed;
`

const Icon = styled(motion.a)`
  background-size: contain;
  margin: 3vw 3vw 0 0;
  filter: grayScale(1) brightness(0.5);
  transition: all ease-in-out 0.5s;
  width: 50px;
  height: 50px;
  &:hover {
    transition: all ease-in-out 0.5s;
    filter: grayScale(0) brightness(1);
  }

  @media ${device.mobile}, @media ${device.mobileS} {
    width: 30px;
    height: 30px;
  }
`

const SocialIcon = styled(motion.div)`
  display: flex;
  flex-direction: row;
`

export default NavList
